export const colors = {
  consileon: {
    primary: '',
    hover: '',
    click: '',
    accent: '',
    'secondary-hover': '',
    'secondary-click': '',
    'text-color-primary': '',
    'text-color-secondary': '',
    'background-element': '',
    'corporate-color-one': '',
    'corporate-border-color': '',
    'corporate-color-three': '',
    path: 'assets/brand-origin/MGPT_logo-mit_RGB ohne Text.svg',
    height: '32',
    width: '32',
  },
  pbb: {
    primary: '#262D2B',
    hover: '#9BC832',
    click: '#8ABE0E',
    accent: '#cc0133',
    'secondary-hover': '#ABABAB',
    'secondary-click': '#919191',
    'text-color-primary': '#262D2B',
    'text-color-secondary': '#ffffff',
    'background-element': '#F5F5F5',
    'corporate-color-one': '#ffffff',
    'corporate-border-color': '#9BC832',
    'corporate-color-three': '#8ABE0E',
    path: 'assets/logo/pbblogo.jpg',
    height: '32',
    width: '99.85',
  },
  'test-tenant': {
    primary: '#262D2B',
    hover: '#9BC832',
    click: '#8ABE0E',
    accent: '#cc0133',
    'secondary-hover': '#ABABAB',
    'secondary-click': '#919191',
    'text-color-primary': '#262D2B',
    'text-color-secondary': '#ffffff',
    'background-element': '#F5F5F5',
    'corporate-color-one': '#ffffff',
    'corporate-border-color': '#9BC832',
    'corporate-color-three': '#8ABE0E',
    path: 'assets/logo/pbblogo.jpg',
    height: '32',
    width: '99.85',
  },
  'api-test-staging': {
    primary: '',
    hover: '',
    click: '',
    accent: '',
    'secondary-hover': '',
    'secondary-click': '',
    'text-color-primary': '',
    'text-color-secondary': '',
    'background-element': '',
    'corporate-color-one': '',
    'corporate-border-color': '',
    'corporate-color-three': '',
    path: 'assets/brand-origin/MGPT_logo-mit_RGB ohne Text.svg',
    height: '32',
    width: '32',
  },
  demo: {
    primary: '',
    hover: '',
    click: '',
    accent: '',
    'secondary-hover': '',
    'secondary-click': '',
    'text-color-primary': '',
    'text-color-secondary': '',
    'background-element': '',
    'corporate-color-one': '',
    'corporate-border-color': '',
    'corporate-color-three': '',
    path: '',
    height: '',
    width: '',
  },
};
