import { Component, signal } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { ProjectRoles } from '../models/project-roles.model';
import { ProjectService } from '../pages/project-dashboard/project.service';
import { AuthService } from '../services/auth.service';
import { EventBus, EventBusEnum } from '../services/event-bus.service';

import { NgOptimizedImage } from '@angular/common';
import {
  PATH_CHATBOT,
  PATH_DOCUMENTS,
  PATH_MANAGEMENT,
  PATH_PROJECT,
  PATH_REPOSITORY,
  PATH_START,
} from '../app.routes';

export enum ProjectPage {
  // Start = 'start',
  Repository = 'repository',
  Chatbot = 'chatbot',
  Management = 'management',
}

const PATH_REGEX = /\/[^/]+\/[^/]+\/[^/]+\/([^/]+)/;

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [SidebarModule, ButtonModule, RouterOutlet, NgOptimizedImage],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  // activeMainTab: Page | undefined;
  // not a signal because [(visible)]="sidebarVisible" won't work with signal
  sidebarVisible = true;

  protected activeTab = signal(ProjectPage.Repository);

  public isAdmin = signal(false);

  constructor(
    private router: Router,
    public authService: AuthService,
    public projectService: ProjectService,
    private eventBus: EventBus,
  ) {
    this.eventBus.event$.subscribe(async (event) => {
      if (event.type === EventBusEnum.PROJECT_SELECTED) {
        this.isAdmin.set(await this.determineIfAdmin());
        this.refreshSideBarVisibility();
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleSidebarVisibility(event.url);
        this.handleTabHighlighting(event.url);
      }
    });
  }

  private refreshSideBarVisibility() {
    this.sidebarVisible = true;
  }

  handleSidebarVisibility(url: string) {
    const isProjectRoute = url.includes(PATH_PROJECT);
    if (!isProjectRoute) {
      this.isAdmin.set(false);
    }
  }

  routeToHome() {
    this.router.navigate([this.authService.tenantAlias]);
  }

  routeToStart() {
    this.router.navigate([this.authService.tenantAlias, PATH_PROJECT, this.projectService.projectId, PATH_START]);
  }

  routeToRepository() {
    this.router.navigate([
      this.authService.tenantAlias,
      PATH_PROJECT,
      this.projectService.projectId,
      PATH_REPOSITORY,
      PATH_DOCUMENTS,
    ]);
  }

  routeToChatbot() {
    this.router.navigate([this.authService.tenantAlias, PATH_PROJECT, this.projectService.projectId, PATH_CHATBOT]);
  }

  routeToProjectManagement() {
    this.router.navigate([this.authService.tenantAlias, PATH_PROJECT, this.projectService.projectId, PATH_MANAGEMENT]);
  }
  protected readonly ProjectPage = ProjectPage;

  private async determineIfAdmin() {
    const projectsRole = await this.authService.getRoleForProject(this.projectService.projectId);
    if (projectsRole == null) {
      return false;
    } else return projectsRole ? projectsRole == ProjectRoles.ADMIN : false;
  }

  private handleTabHighlighting(url: string) {
    const match = url.match(PATH_REGEX);

    match ? this.activeTab.set(match[1] as ProjectPage) : null;
  }
}
