import { Injectable, isDevMode } from '@angular/core';
import { AuthService } from './auth.service';
import { colors } from 'src/environments/custom-config/custom-configs';

interface TenantConfig {
  primary: string;
  hover: string;
  click: string;
  accent: string;
  'secondary-hover': string;
  'secondary-click': string;
  'text-color-primary': string;
  'text-color-secondary': string;
  'background-element': string;
  'corporate-color-one': string;
  'corporate-border-color': string;
  'corporate-color-three': string;
  path: string;
  height: string;
  width: string;
}

@Injectable({
  providedIn: 'root',
})
export class UiCustomizationService {
  private tenantColors: { [key: string]: TenantConfig }[] = [colors];

  // colors initialized to be able to use type safety
  private defaultColors = {
    primary: '',
    hover: '',
    click: '',
    accent: '',
    'secondary-hover': '',
    'secondary-click': '',
    'text-color-primary': '',
    'text-color-secondary': '',
    'background-element': '',
    'corporate-color-one': '',
    'corporate-border-color': '',
    'corporate-color-three': '',
  } as const;

  applyGlobalColors(tenantAlias: string): void {
    isDevMode()
      ? console.log(
          `${AuthService.performanceInSeconds()}s: ui-customization-service: apply global colors. TenantAlias: ${tenantAlias}`,
        )
      : null;

    Object.keys(this.defaultColors).forEach((currentColorKey) => {
      // @ts-expect-error string is key of defaultColors
      const colorValue = this.getGlobalColor(currentColorKey, tenantAlias);
      document.documentElement.style.setProperty(`--${currentColorKey}`, colorValue);
    });
  }

  private getGlobalColor(key: keyof typeof this.defaultColors, tenantAlias: string): string {
    const tenantObject = this.tenantColors.find((tenant) => tenant[tenantAlias]);

    if (tenantObject) {
      const tenantConfig: TenantConfig = tenantObject[tenantAlias];
      const colorFromConfig = tenantConfig[key];

      // Return the color from the tenant config or default if not found
      return colorFromConfig || this.defaultColors[key];
    } else {
      console.warn(`Tenant with alias "${tenantAlias}" not found. Using default colors.`);
      return this.defaultColors[key];
    }
  }

  public getHeaderLogoData(tenantAlias: string): TenantConfig | undefined {
    const tenantObject = this.tenantColors.find((tenant) => tenant[tenantAlias]);

    if (tenantObject) {
      const tenantConfig: TenantConfig | undefined = tenantObject[tenantAlias];
      if (tenantConfig.path == '' || tenantConfig.width == '' || tenantConfig.height == '') {
        return this.getStandardLogoInformation();
      }
      return tenantConfig;
    } else {
      console.warn(`Tenant with alias "${tenantAlias}" not found in tenantColors.`);
      return undefined;
    }
  }

  private getStandardLogoInformation(): TenantConfig | undefined {
    const tenantObjectConsileon = this.tenantColors.find((tenant) => tenant['consileon']);
    if (tenantObjectConsileon) {
      const tenantConfigConsileon: TenantConfig | undefined = tenantObjectConsileon['consileon'];
      return tenantConfigConsileon;
    } else {
      return undefined;
    }
  }
}
