import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { firstValueFrom, map, take, tap } from 'rxjs';

export const authGuard: CanActivateFn = async (route, state) => {
  const auth: AuthService = inject(AuthService);

  const user = await firstValueFrom(auth.waitForLoggedInUsersTokenIsAvailable());
  return !!user;
};
