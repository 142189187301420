import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { projectRoleGuard } from './guards/project-role.guard';
import { ProjectRoles } from './models/project-roles.model';
import { userDataResolver } from './services/user-data-resolver';
import { tenantInitializer } from './services/tenant-initializer';
import { projectServiceInitializer } from './services/project-service-initializer';
import { colorInitializer } from './services/color-initializer';

export const PATH_START = 'start';
export const PATH_REPOSITORY = 'repository';
export const PATH_DOCUMENTS = 'documents';
export const PATH_CHATBOT = 'chatbot';
export const PATH_MANAGEMENT = 'management';
export const PATH_PROJECT = 'project-dashboard';
export const PATH_LOGIN = 'login';
export const PATH_PROJECTS_OVERVIEW = 'projects-overview';
export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/homepage/homepage.component').then((m) => m.HomepageComponent),
  },
  {
    path: 'documentation',
    loadComponent: () => import('./pages/documentation/documentation.component').then((m) => m.DocumentationComponent),
  },
  {
    path: ':tenant',
    resolve: { tenantId: tenantInitializer, colorsInitialized: colorInitializer },
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/homepage/homepage.component').then((m) => m.HomepageComponent),
      },
      {
        path: 'documentation',
        loadComponent: () =>
          import('./pages/documentation/documentation.component').then((m) => m.DocumentationComponent),
      },
      {
        path: PATH_PROJECTS_OVERVIEW,
        canActivate: [authGuard],
        resolve: { userData: userDataResolver },
        loadComponent: () =>
          import('./pages/projects-overview/projects-overview.component').then((m) => m.ProjectsOverviewComponent),
      },
      {
        path: PATH_LOGIN,
        loadComponent: () => import('./header/sign-in/sign-in.component').then((m) => m.SignInComponent),
      },
      {
        path: 'profile',
        canActivate: [authGuard],
        loadComponent: () => import('./header/profile/profile.component').then((m) => m.ProfileComponent),
      },
      {
        path: PATH_PROJECT + '/:projectId',
        canActivate: [projectRoleGuard],
        resolve: { userData: userDataResolver, projectData: projectServiceInitializer },
        data: {
          requiredRoles: [ProjectRoles.ADMIN, ProjectRoles.USER],
        },
        loadComponent: () =>
          import('./pages/project-dashboard/project-dashboard.component').then((m) => m.ProjectDashboardComponent),
        children: [
          {
            path: PATH_START,
            canActivate: [projectRoleGuard],
            data: {
              requiredRoles: [ProjectRoles.ADMIN],
            },
            loadComponent: () =>
              import('./pages/project-dashboard/project-dashboard-start/project-dashboard-start.component').then(
                (m) => m.ProjectDashboardStartComponent,
              ),
          },
          {
            path: PATH_REPOSITORY,
            canActivate: [projectRoleGuard],
            data: {
              requiredRoles: [ProjectRoles.ADMIN],
            },
            loadComponent: () =>
              import(
                './pages/project-dashboard/project-dashboard-repository/project-dashboard-repository.component'
              ).then((m) => m.ProjectDashboardRepositoryComponent),
            children: [
              {
                path: PATH_DOCUMENTS,
                loadComponent: () =>
                  import('./pages/project-dashboard/project-dashboard-repository/documents/documents.component').then(
                    (m) => m.DocumentsComponent,
                  ),
              },
              {
                path: 'websites',
                loadComponent: () =>
                  import('./pages/project-dashboard/project-dashboard-repository/websites/websites.component').then(
                    (m) => m.WebsitesComponent,
                  ),
              },
            ],
          },
          {
            path: PATH_CHATBOT,
            canActivate: [projectRoleGuard],
            data: {
              requiredRoles: [ProjectRoles.ADMIN, ProjectRoles.USER],
            },
            loadComponent: () =>
              import('./pages/project-dashboard/project-dashboard-chatbot/project-dashboard-chatbot.component').then(
                (m) => m.ProjectDashboardChatbotComponent,
              ),
          },
          {
            path: PATH_MANAGEMENT,
            canActivate: [projectRoleGuard],
            data: {
              requiredRoles: [ProjectRoles.ADMIN],
            },
            loadComponent: () =>
              import(
                './pages/project-dashboard/project-dashboard-management/project-dashboard-management.component'
              ).then((m) => m.ProjectDashboardManagementComponent),
          },
        ],
      },
    ],
  },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];
