import { inject, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { UiCustomizationService } from './ui-customization.service';

// maybe not the cleanest solution to do this within a resolver, but no better solution was found at the time of implementation.
export const colorInitializer = async (route: ActivatedRouteSnapshot) => {
  const uiCustomizationService = inject(UiCustomizationService);

  const tenantAlias = route?.paramMap.get('tenant') || '';
  isDevMode()
    ? console.log(
        `${AuthService.performanceInSeconds()}s: auth initialized via tenant initializer. Detected tenant: ${tenantAlias}`,
      )
    : null;

  uiCustomizationService.applyGlobalColors(tenantAlias);

  return true;
};
