import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ProjectData, ProjectService } from '../pages/project-dashboard/project.service';

export const projectServiceInitializer: ResolveFn<any> = async (
  route: ActivatedRouteSnapshot,
): Promise<ProjectData | undefined> => {
  const projectService = inject(ProjectService);

  await projectService.init(route.paramMap.get('projectId')!);

  return projectService.projectData;
};
