import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ProjectRoles } from '../models/project-roles.model';

export const projectRoleGuard: CanActivateFn = async (route, state) => {
  const authService: AuthService = inject(AuthService);

  const projectId =
    route.paramMap.get('projectId') || route.parent?.paramMap.get('projectId') || 'IMPROBABLE_PROJECT_ID';
  const projectsRole: ProjectRoles | null = await authService.getRoleForProject(projectId);

  if (projectsRole) {
    const requiredRoles: string[] = route.data['requiredRoles'];
    return requiredRoles.includes(projectsRole);
  } else return false;
};
