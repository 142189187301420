import { inject, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TenantUtil } from '../utils/tenant-util';
import { AuthService } from './auth.service';

// maybe not the cleanest solution to do this within a resolver, but no better solution was found at the time of implementation.
export const tenantInitializer = async (route: ActivatedRouteSnapshot) => {
  const authService = inject(AuthService);

  const tenantAlias = route?.paramMap.get('tenant') || '';
  isDevMode()
    ? console.log(
        `${AuthService.performanceInSeconds()}s: auth initialized via tenant initializer. Detected tenant: ${tenantAlias}`,
      )
    : null;
  const tenantId = TenantUtil.determineTenantIdFromTenantAlias(tenantAlias);

  authService.initWithTenantId(tenantId, tenantAlias);

  return tenantId;
};
