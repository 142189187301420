import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from './auth.service';

export const userDataResolver = async () => {
  const authService = inject(AuthService);

  await firstValueFrom(authService.user$!);

  return authService.user$!;
};
