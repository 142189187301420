@if (isAdmin()) {
  <div class="sidebar-container">
    @if (isAdmin()) {
      <div class="sidebar-module-container">
        <p-sidebar
          [(visible)]="sidebarVisible"
          [modal]="false"
          class="menu-sidebar"
          styleClass="sidebar"
          [showCloseIcon]="true"
          closeIcon="pi pi-arrow-left"
        >
          <div class="pd-navigation">
            <!-- <h5
          class="pd-navigation-tab-headers start"
          [class.pd-navigation-tab-headers-selected]="activeTabSignal() === ProjectPage.Start"
          (click)="routeToStart()"
        >
          Start
        </h5> -->
            <h5
              class="pd-navigation-tab-headers start"
              [class.pd-navigation-tab-headers-selected]="activeTab() === ProjectPage.Repository"
              (click)="routeToRepository()"
            >
              Ablage
            </h5>
            <h5
              class="pd-navigation-tab-headers"
              [class.pd-navigation-tab-headers-selected]="activeTab() === ProjectPage.Chatbot"
              (click)="routeToChatbot()"
            >
              Chatbot
            </h5>
            <h5
              class="pd-navigation-tab-headers end"
              [class.pd-navigation-tab-headers-selected]="activeTab() === ProjectPage.Management"
              (click)="routeToProjectManagement()"
            >
              Projektverwaltung
            </h5>
          </div>
        </p-sidebar>
        <p-button
          styleClass="sidebar-menu-button secondary-button"
          (click)="sidebarVisible = !sidebarVisible"
          icon="pi pi-align-justify"
          [styleClass]="sidebarVisible ? 'sidebar-menu-highlighted' : ''"
        >
        </p-button>
      </div>
    }
  </div>
}
